@import '../../../../../../estee_base/scss/theme-bootstrap';
@import '../../../../scss/sections/variable';

.custom-collection-quickshop-open {
  height: 100vh;
  @media (min-width: $tablet-width-min) {
    height: auto;
  }
  body {
    height: 100vh;
    overflow-y: hidden;
    padding-right: 15px; /* Avoid width reflow */
    @media (min-width: $tablet-width-min) {
      height: auto;
      overflow-y: auto;
      padding-right: 0;
    }
  }
  .custom-collection-product-brief-v1 {
    opacity: 0.3;
    &.active {
      opacity: 1;
    }
  }
}

.custom-collection-product-quickshop-wrapper {
  display: none;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
  &.active {
    bottom: 0;
    display: block;
    height: calc(100vh - 83px);
    left: 0;
    overflow: scroll;
    position: fixed;
    top: 83px;
    z-index: 1010;
    @media (min-width: $tablet-width-min) {
      display: block;
      height: auto;
      left: auto;
      margin: -90px auto 40px;
      max-width: 1066px;
      overflow: visible;
      padding: 20px 20px 0;
      position: relative;
      top: auto;
      z-index: 15;
      &::before {
        content: '';
        border-bottom: 20px solid #{$color-whitesmoke};
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        margin-left: -20px;
        position: absolute;
        top: 0;
      }
    }
  }
}

.custom-collection-product-quickshop-v1 {
  background-color: #{$color-whitesmoke};
  .product-quickshop__container {
    position: relative;
  }
  .product-quickshop__price-wrapper {
    float: left;
    padding-right: 40px;
    width: 50%;
    &--top {
      float: none;
      padding: 0;
      width: auto;
      @media (min-width: $tablet-width-min) {
        display: none;
      }
    }
  }
  .product-quickshop__cta {
    float: left;
    width: 50%;
    .button {
      background-color: #{$color-navy};
      border: 1px solid #{$color-navy};
      color: #{$color-white};
      display: inline-block;
      font-size: 0.75rem;
      font-weight: 700;
      height: 33px;
      letter-spacing: 0.15em;
      line-height: 2.83;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      &:focus {
        background-color: #{$color-white};
        color: #{$color-navy};
      }
    }
  }
  @media (min-width: $tablet-width-min) {
    padding: 40px;
    .product-select {
      clear: both;
      float: left;
    }
    .product-quickshop-wrapper.active {
      height: auto;
      left: auto;
      overflow: auto;
      padding: 0;
      position: relative;
      top: auto;
    }
  }
  .product-quickshop__container::after {
    clear: both;
    content: '';
    display: table;
  }
  .product-quickshop__media {
    margin: 0 auto 20px;
    max-width: 375px;
    @media (min-width: $tablet-width-min) {
      float: left;
      margin: 0 30px 0 0;
      max-width: 446px;
      width: 50%;
    }
  }
  .product-full__image-img {
    width: 100%;
  }
  .product-full__image {
    margin: 0 auto;
    position: relative;
    text-align: center;
  }
  .product-quickshop__content {
    padding: 0 10px;
    @media (min-width: $tablet-width-min) {
      overflow: hidden;
      padding: 0;
    }
  }
  .product-name {
    font-family: $optimalight;
    font-size: 2.5rem;
    line-height: 1.3;
    @media (min-width: $tablet-width-min) {
      font-size: 3.125rem;
    }
    b {
      display: block;
      font-family: $optimaregular, $optimalight;
      font-size: 1.375rem;
      line-height: 1.272727;
    }
  }
  .product-subheading {
    font-family: $optimalight;
    font-size: 1.375rem;
    letter-spacing: normal;
    line-height: 1.227273;
    margin-bottom: 1rem;
    margin-top: 0;
  }
  .product_brief__reviews {
    margin-bottom: 1rem;
  }
  .product-quickshop__rating {
    @media (min-width: $tablet-width-min) {
      float: right;
    }
    .pr-snippet-stars {
      background: transparent;
    }
  }
  .sku-list-sizes__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .sku-list-sizes__button {
    background-color: $color-navy;
    color: $color-white;
    display: block;
    font-size: 0.75rem;
  }
  .sku-list-sizes__button--active {
    background-color: #{$color-coconut};
    box-shadow: inset 0 0 0 1px $color-navy;
    color: $color-navy;
    cursor: auto;
    text-decoration: none;
  }
  .button--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .sku-list-sizes__item {
    margin-top: 10px;
    width: 48%;
  }
  .product-quickshop-header {
    margin: 30px 0;
  }
  .product-quickshop__sku-select {
    padding-top: 10px;
  }
  .button--quickshop {
    margin: 0 0 20px;
  }
  .product-quickshop__footer {
    float: none;
    margin: 0;
    width: 100%;
  }
  .product-quickshop__close {
    display: block;
    padding: 0 10px;
    text-align: right;
    @media (min-width: $tablet-width-min) {
      background: none;
      border: 0;
      padding: 20px;
      position: absolute;
      right: -40px;
      text-decoration: none;
      top: -40px;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    .icon--close {
      display: inline-block;
      height: 20px;
      position: relative;
      vertical-align: middle;
      width: 20px;
      @media (min-width: $tablet-width-min) {
        display: block;
        height: 35px;
        width: 35px;
      }
      &:hover,
      &:focus {
        &:before,
        &:after {
          background-color: $color-navy;
        }
      }
      &:before,
      &:after {
        background-color: $color-navy;
        content: '';
        height: 20px;
        left: 10px;
        position: absolute;
        top: 0;
        width: 2px;
        @media (min-width: $tablet-width-min) {
          background-color: #{$color-silver};
          height: 35px;
          left: 17px;
          top: 0;
          width: 1px;
        }
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  .product-quickshop__close-text {
    display: inline-block;
    font-family: $optimaregular, $optimalight;
    font-size: 1.125rem;
    line-height: 2.5;
    vertical-align: middle;
    @media (min-width: $tablet-width-min) {
      left: -999em;
      position: absolute;
    }
  }
  .product-sku-price {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 2.142857;
  }
  &.product-sku-price__value:first-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .product-sku-price__size {
    padding-right: 15px;
  }
  .product-sku-unit-price {
    display: block;
  }
  .product-quickshop__price {
    margin: 0 0 20px;
  }
}

.custom-collection-accordion {
  border-bottom: 1px solid $color-navy;
  margin: 0 0 20px;
  text-align: left;
  @media (min-width: $tablet-width-min) {
    .product-shade-picker__shades::after {
      clear: both;
      content: '';
      display: table;
    }
  }
  .product-shade-picker__shades {
    margin: 0 -2px;
  }
  .product-shade-picker__shade {
    border: 3px solid #{$color-whitesmoke};
    border-radius: 50%;
    display: inline-block;
    height: 31px;
    margin: 0 2px 6px;
    position: relative;
    text-indent: -999rem;
    width: 31px;
    &.shade-active {
      border-color: transparent;
      &:after {
        border: 2px solid #{$color-whitesmoke};
        border-radius: 50%;
        content: '';
        height: 29px;
        left: -2px;
        position: absolute;
        top: -2px;
        width: 29px;
      }
    }
  }
}

.custom-collection-accordion__header {
  font-family: $akzidenz;
  font-size: 0.75rem;
  font-weight: 700;
  height: 45px;
  letter-spacing: 0.15em;
  line-height: 3.75;
  margin: 0;
}

.custom-collection-accordion__header-link {
  border-top: 1px solid #{$color-navy};
  display: block;
  margin: 0;
  padding: 0 50px 0 0;
  position: relative;
  text-transform: uppercase;
  &::before,
  &::after {
    background: #{$color-navy};
    content: '';
    position: absolute;
    text-align: left;
  }
  &::after {
    height: 2px;
    margin-top: -1px;
    right: 0;
    top: 50%;
    width: 16px;
  }
  &::before {
    height: 16px;
    margin-top: -8px;
    right: 7px;
    top: 50%;
    width: 2px;
  }
  &.active-header::before {
    display: none;
  }
}

.custom-collection-accordion__content {
  padding: 0 0 15px;
}

.custom-collection__prices-multi-sized {
  display: none;
  &--active {
    display: block;
  }
}

@media (min-width: $tablet-width-min) {
  .product-grid--medium-cols-1 {
    .custom-collection-product-quickshop-wrapper {
      &.active--1::before {
        left: 50%;
      }
      &.active--1::before {
        left: 25%;
      }
      &.active--2::before {
        left: 75%;
      }
    }
  }
  .product-grid--medium-cols-3 {
    .custom-collection-product-quickshop-wrapper {
      &.active--1::before {
        left: 16.6666665%;
      }
      &.active--2::before {
        left: 50%;
      }
      &.active--3::before {
        left: 83.3333325%;
      }
    }
    .custom-collection-accordion {
      .product-shade-picker__shades {
        float: none;
        max-width: none;
        padding-left: 1px; /* To compensate for the clipping from the requirement of an overflow hidden on a parent container. */
      }
    }
  }
  .product-grid--medium-cols-4 {
    .custom-collection-product-quickshop-wrapper {
      &.active--1::before {
        left: 12.5%;
      }
      &.active--2::before {
        left: 37.5%;
      }
      &.active--3::before {
        left: 62.5%;
      }
      &active--4::before {
        left: 87.5%;
      }
    }
  }
}

@media (min-width: $medium-max) {
  .product-grid--medium-cols-3 {
    .custom-collection-product-quickshop-wrapper {
      &.active--1::before {
        left: 13.6666665%;
      }
      &.active--3::before {
        left: 86.3333325%;
      }
    }
  }
}
